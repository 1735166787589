//form field
.mdc-text-field--outlined {
    --mdc-outlined-text-field-container-shape: 12px !important;
}

.mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
    top: 50% !important;
}

.mdc-floating-label--float-above {
    top: 80% !important;
}

.mat-mdc-form-field-error-wrapper {
    padding: 0 8px !important;
}

.mat-mdc-form-field-error {
    font-size: 10px !important;
}

.mdc-text-field__input {
    color: var(--color-tertiary) !important;
    line-height: normal !important;
    font-size: 1rem !important;
}

//mat tab
.mat-mdc-tab-list {
    border-bottom: 1px solid var(--color-stroke);
}

.mat-mdc-tab-link .mdc-tab__text-label {
    font-size: 16px !important;
    line-height: 16px !important;
    font-weight: 600 !important;
}

.mat-mdc-tab-link .mdc-tab-indicator__content--underline {
    border-top-width: 4px !important;
}

.mat-mdc-tab-header.mat-mdc-tab-nav-bar-stretch-tabs .mat-mdc-tab-link {
    min-width: 140px !important;
    flex-grow: unset !important;
}

.mat-mdc-tab-nav-bar {
    --mat-tab-header-disabled-ripple-color: var(--color-tertiary-medium);
    --mat-tab-header-inactive-label-text-color: var(--color-tertiary-medium);
    --mat-tab-header-active-label-text-color: var(--color-primary);
    --mat-tab-header-active-ripple-color: var(--color-white);
    --mat-tab-header-inactive-ripple-color: var(--color-white);
    --mat-tab-header-inactive-focus-label-text-color: var(--color-tertiary-medium);
    --mat-tab-header-inactive-hover-label-text-color: var(--color-tertiary-medium);
    --mat-tab-header-active-focus-label-text-color: var(--color-primary);
    --mat-tab-header-active-hover-label-text-color: var(--color-primary);
    --mat-tab-header-active-focus-indicator-color: var(--color-primary);
    --mat-tab-header-active-hover-indicator-color: var(--color-primary);
}

.mat-mdc-select-min-line {
    color: var(--color-tertiary) !important;
    font-size: 15px !important;
}

.mdc-list-item {
    font-size: 12px !important;
}

.mat-mdc-paginator-page-size-label {
    color: var(--color-tertiary);
}

.mat-mdc-paginator-range-label {
    color: var(--color-tertiary);
}

.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
    padding: 0 12px 0 12px !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    background: var(--color-white) !important;
    color: var(--color-tertiary) !important;
}

.mdc-snackbar__label {
    padding: 0 !important;
}

// mat dialog box
.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    box-shadow: none !important;
}

.cdk-overlay-dark-backdrop {
    background: var(--color-shadow-blue) !important;
    backdrop-filter: blur(4px) !important;
}

.mat-mdc-dialog-container .mat-mdc-dialog-content {
    padding: 10px 30px 0 30px !important;
}

.mat-mdc-dialog-content {
    max-height: 80vh !important;
}

// mat dialog box
.mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 20px !important;
    box-shadow: 0 0 0 1px var(--color-stroke), 0 15px 35px -5px var(--color-primary-extra-light-effect), 0 5px 15px var(--color-primary-extra-light-effect) !important;
}

.mat-mdc-menu-item-text {
    display: block !important;
    width: 100%;
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
    box-shadow: 0 0 0 1px var(--color-stroke), 0 15px 35px -5px var(--color-primary-extra-light-effect), 0 5px 15px var(--color-primary-extra-light-effect) !important;
}

.mat-mdc-header-cell {
    font-weight: 600 !important;
    font-size: 14px !important;
    color: var(--color-grey-light-dark) !important;
}

th.mat--mdc-header-cell, td.mat-mdc-cell, td.mat-mdc-footer-cell {
    border-bottom-color: var(--color-stroke) !important;
}

tr.mat-mdc-row:hover {
    background: var(--color-selected-mat-row) !important;
}

th {
    top: unset !important;
}

//quill-editor
quill-editor {
    width: 100% !important;
}

.mat-mdc-menu-item-text {
    display: flex;
    align-items: center;
}

.selected-row {
    background: var(--color-border-gray) !important;
}

.mdc-list-item__primary-text {
    font-size: 16px !important;
}

b, strong {
    font-weight: 500;
}

.mat-mdc-text-field-wrapper {
    background: var(--color-white) !important;
    border-radius: 12px !important;
}

.mat-mdc-form-field-infix {
    padding: 0 5px 5px !important;
    min-height: 44px !important;
    display: flex !important;
    align-items: center !important;
    width: 100% !important;
    top: 4px !important;
    gap: 8px !important;
}

.mat-mdc-form-field-infix .mat-mdc-chip-grid {
    width: 100% !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
    height: max-content !important;
}

.mat-mdc-select-panel {
    overflow: unset !important;
    max-height: max-content !important;
    padding: 0 !important;
}

.mat-mdc-slider-visual-thumb {
    display: none !important;
}

//mat stepper
.mat-step-header.cdk-keyboard-focused,
.mat-step-header.cdk-program-focused,
.mat-step-header:hover:not([aria-disabled]),
.mat-step-header:hover[aria-disabled=false] {
    background: var(--color-transparent) !important;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
    padding: calc((var(--mat-stepper-header-height) - 44px) / 2) 24px !important;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-icon {
    margin-top: 10px !important;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-label {
    padding: 8px 0 0 0 !important;
}

.mat-horizontal-content-container {
    padding: 0 !important;
}

.mat-mdc-select-panel-above {
    height: 200px !important;
    background: var(--color-white);
    overflow: auto;
    border: 1px solid var(--color-stroke);
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
    background: var(--color-stroke) !important;
}

.mat-mdc-select-panel-above div.mat-mdc-select-panel {
    background: none !important;
    box-shadow: none !important;
}

.firebaseui-container {
    max-width: 100% !important;
}

.firebaseui-idp-button, .firebaseui-tenant-button {
    max-width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.mat-mdc-select-arrow {
    margin-left: 8px !important;
}

.mat-mdc-menu-panel {
    max-width: 100% !important;
    border-radius: 24px !important;
}

.mat-mdc-menu-item:not([disabled]):hover {
    border-radius: 12px !important;
}

.mdc-button__label {
    width: 100% !important;
}

.mat-mdc-menu-item-text {
    display: block !important;
    width: 100%;
}

.mdc-evolution-chip-set__chips {
    width: 100% !important;
    display: flex !important;
    flex-wrap: wrap;
}

//checkbox
.mdc-checkbox__background {
    border-radius: 6px !important;
}

.mat-mdc-checkbox.mat-primary {
    --mdc-checkbox-selected-focus-icon-color: var(--color-primary);
    --mdc-checkbox-selected-hover-icon-color: var(--color-primary);
    --mdc-checkbox-selected-icon-color: var(--color-primary);
    --mdc-checkbox-selected-pressed-icon-color: var(--color-primary);
    --mdc-checkbox-selected-focus-state-layer-color: var(--color-primary);
    --mdc-checkbox-selected-pressed-state-layer-color: var(--color-primary);
}


//customized tool tip

.mdc-tooltip__surface {
    background: var(--color-tertiary) !important;
    color: var(--color-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
}

.mdc-tooltip {
    display: flex;
    padding: 4px 7px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 7px;
    background: var(--color-tertiary);
    position: relative;

    &::after {
        width: 0;
        height: 0;
        content: '';
        position: absolute;
    }

    &.below {
        overflow: initial;
        margin-top: 1rem;

        &:after {
            border-left: 0.5rem solid transparent;
            border-right: 0.5rem solid transparent;
            border-bottom: 0.5rem solid var(--color-tertiary);
            top: -0.5rem;
            right: calc(50% - 0.5rem);
            transform: rotate(0);
        }
    }

    &.above {
        overflow: initial;
        margin-bottom: 1rem;

        &:after {
            border-left: 0.5rem solid transparent;
            border-right: 0.5rem solid transparent;
            border-bottom: 0.5rem solid var(--color-tertiary);
            bottom: -0.5rem;
            right: calc(50% - 0.5rem);
            transform: rotate(180deg);
        }
    }

    &.right {
        overflow: initial;
        margin-left: 1rem;

        &:after {
            border-left: 0.5rem solid transparent;
            border-right: 0.5rem solid transparent;
            border-bottom: 0.5rem solid var(--color-tertiary);
            left: -0.75rem;
            top: calc(50% - 0.25rem);
            transform: rotate(270deg);
        }
    }

    &.left {
        overflow: initial;
        margin-right: 1rem;

        &:after {
            border-left: 0.5rem solid transparent;
            border-right: 0.5rem solid transparent;
            border-bottom: 0.5rem solid var(--color-tertiary);
            right: -0.75rem;
            top: calc(50% - 0.25rem);
            transform: rotate(90deg);
        }
    }
}

//toggle
.mat-mdc-slide-toggle.mat-accent {
    --mdc-switch-selected-focus-state-layer-color: var(--color-primary) !important;
    --mdc-switch-selected-handle-color: var(--color-primary) !important;
    --mdc-switch-selected-hover-state-layer-color: var(--color-primary) !important;
    --mdc-switch-selected-pressed-state-layer-color: var(--color-primary) !important;
    --mdc-switch-selected-focus-handle-color: var(--color-primary) !important;
    --mdc-switch-selected-hover-handle-color: var(--color-primary) !important;
    --mdc-switch-selected-pressed-handle-color: var(--color-primary) !important;
    --mdc-switch-selected-focus-track-color: var(--color-primary) !important;
    --mdc-switch-selected-hover-track-color: var(--color-primary) !important;
    --mdc-switch-selected-pressed-track-color: var(--color-primary) !important;
    --mdc-switch-selected-track-color: var(--color-primary) !important;
}
